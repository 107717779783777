<template>
  <div :key="`CR${consent_request.id}`">
    <v-card class="mb-4" outlined>
      <v-card-title class="pb-0">
        Respostas
      </v-card-title>
      <v-card-text class="mt-0">
        <template v-if="consent_request.answers.length == 0">
          Ainda não há respostas para esta solicitação de consentimento.
        </template>
        <v-card
          v-for="answer in consent_request.answers" :key="answer.id"
          :color="answer.answer == 'accepted' ? 'success':'warning'"
          class="white--text text-subtitle-2 pa-4 mt-2"
        >
          <span class="font-weight-bold">
            <template v-if="answer.answer == 'accepted'" }}>
              Aceito
            </template>
            <template v-else-if="answer.answer == 'refused'" }}>
              Recusado
            </template>
            <template v-else>
              Revogado
            </template>
          </span>
          em {{ $utils.formatDatetime(answer.consent_datetime) }}
        </v-card>
      </v-card-text>
    </v-card>
    <ConsentDataPanel
      title="Finalidades" class="my-1"
      :height="`${10+40*consent_request.purposes.length}px`"
    >
      <p
        v-for="(purpose, i) in consent_request.purposes" :key="`PR${i}`"
        class="my-1 font-weight-medium"
      >
        {{ purpose }}
      </p>
    </ConsentDataPanel>
    <ConsentDataPanel
      title="Dados do Titular" class="my-1"
      :height="`${10+58*consent_request.bearerInfos.length}px`"
      :data="consent_request.content.bearerInfos"
    />
    <div class="d-flex align-self">
      <ConsentDataPanel
        title="Forma de Tratamento"
        class="mr-1 flex-grow-1"
        height="70px"
      >
        <div class="font-weight-medium">
          {{ consent_request.content.means.title }}
        </div>
        <div>
          {{ consent_request.content.means.description }}
        </div>
      </ConsentDataPanel>
      <ConsentDataPanel
        title="Duração"
        class="ml-1 flex-grow-1"
        height="70px"
      >
        <strong>
          {{ consent_request.content.duration.name }}
        </strong>
      </ConsentDataPanel>
    </div>
    <ConsentDataPanel
      title="Controladores" class="my-1"
      :height="`${20+68*consent_request.controllers.length}px`"
    >
      <v-list dense color="transparent" class="my-n4">
        <v-list-item
          v-for="controller in consent_request.content.controllers"
          :key="`CTR${controller.id}`"
          class="px-0 py-1"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ controller.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ controller.email }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ controller.phone }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </ConsentDataPanel>
    <ConsentDataPanel
      title="Responsabilidades dos Agentes" class="my-1"
      :height="`${20+50*consent_request.responsibilities.length}px`"
      :data="consent_request.content.responsibilities"
    />
    <ConsentDataPanel
      title="Consequências de não Consentimento" class="my-1"
      :height="`${20+50*consent_request.consequences.length}px`"
      :data="consent_request.content.consequences"
    />
    <ConsentDataPanel
      title="Riscos do Tratamento de Dados" class="my-1"
      :height="`${20+50*consent_request.risks.length}px`"
      :data="consent_request.content.risks"
    />
    <ConsentDataPanel title="Informações Adicionais" class="my-1" height="100px">
      <div>
        {{ consent_request.additional_info || "Nenhuma informação adicional informada" }}
      </div>
    </ConsentDataPanel>
    <v-divider class="my-6 info" />
  </div>
</template>

<script>
import ConsentDataPanel from "@/components/ConsentDataPanel.vue";

export default {
  name: "ConsentRequestContent",

  components: { ConsentDataPanel, },

  props: {
    consent_request: {required: true, type: Object},
  },

}
</script>
