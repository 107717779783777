<template>
  <div class="grey lighten-4 fill-height d-flex justify-center align-start">
    <v-card width="100%" max-width="1024px" class="my-10 mx-4">
      <v-card-title>
        Solicitação de Consentimento
      </v-card-title>
      <v-card-subtitle>
        Último passo para responder o consentimento
      </v-card-subtitle>
      <v-expand-transition>

        <!-- LOADING -->
        <div
          v-if="loadingData" key="loading" 
          class="d-flex justify-center"
        >
          <v-progress-circular
            class="my-4"
            indeterminate
            background-color="secondary"
            size="50"
          />
        </div>

        <!-- UNEXPECTED ERROR -->
        <v-card-text v-else-if="unexpectedError" key="unexpected" class="d-flex flex-column align-center">
          <h2 class="text-subtitle-1 mt-2">
            Erro inesperado ao carregar os dados do consentimento
          </h2>
          <v-btn small depressed color="primary" class="my-4" @click="getData()">
            Tentar novamente
          </v-btn>
        </v-card-text>

        <!-- NOT FOUND ERROR -->
        <v-card-text v-else-if="notFoundError" key="notFound" class="d-flex flex-column align-center">
          <h2 class="text-h6 font-weight-bold mt-10">
            Solicitação de consentimento não encontrada
          </h2>
          <p class="text-subtitle-1 font-weight-medium mb-10">
            A solicitação pode já ter sido respondida.
          </p>
          <p class="text-subtitle-1 font-weight-medium">
            Para gerenciar suas solicitações de consentimento, acesse o eConsenso pelo
            <v-btn
              color="primary" small depressed
              :to="{name: 'landing-install-app'}"
            >
              aplicativo
              <v-icon class="ml-1" small>mdi-open-in-new</v-icon>
            </v-btn>
          </p>
        </v-card-text>
        
        <!-- CONTENT -->
        <v-card-text v-else key="content">
          <ConsentDataPanel title="Titular dos Dados" height="100px" class="my-2">
            <div class="font-weight-medium text-h6">
              {{ bearer.name }}
            </div>
            <div> {{ bearer.cpf }}</div>
            <div> {{ bearer.email }}</div>
            <div> {{ bearer.phone }}</div>
          </ConsentDataPanel>
          <ConsentRequestContent :consent_request="consent.consent_requests[0]" />
          <div class="d-flex mt-6">
            <v-btn
              color="error"
              x-large class="flex-grow-1 mr-2"
              @click="confirmDialog = 'refused'"
            >
              Recusar
            </v-btn>
            <v-btn
              color="success"
              x-large class="flex-grow-1 ml-2"
              @click="confirmDialog = 'accepted'"
            >
              Aceitar
            </v-btn>
          </div>          
          <p class="font-weight-medium text-center mt-4 mb-0">
            Após responder, será necessário entrar pelo aplicativo para gerenciar o consentimento.
          </p>
        </v-card-text>
      </v-expand-transition>
    </v-card>

    <v-dialog
      :value="confirmDialog"
      persistent
      width="550px"
    >
      <v-card v-if="consent.consent_requests != undefined">
        <v-card-title>
          Deseja realmente
          {{ confirmDialog == 'accepted' ? "Aceitar":"Recusar" }}
          esse consentimento?
        </v-card-title>
        <v-card-text
          v-if="consent.consent_requests[0].content[confirmDialog == 'accepted' ? 'risks':'consequences'].length > 0"
          class="pb-0"
        >
          <div class="text-subtitle-1 black--text">
          {{
            confirmDialog == 'accepted' ?
              "Riscos do Tratamento de Dados"
             :"Consequências de não Consentimento"
          }}:
          </div>
          <div
            v-for="item in consent.consent_requests[0].content[confirmDialog == 'accepted' ? 'risks':'consequences']"
            :key="item.id"
            class="p-2 black--text"
          >
            <v-divider class="my-3" />
            <div class="text-subtitle-2">
              {{ item.title }}
            </div>
            <p class="mb-0">
              {{ item.description }}
            </p>
          </div>
          <v-divider class="mt-2 mb-4" />
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer/>
          <v-btn
            large text
            :disabled="loadingSave"
            @click="confirmDialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            large text
            :color="confirmDialog == 'accepted' ? 'success':'error'"
            @click="sendAnswer()"
            :loading="loadingSave"
          >
            {{ confirmDialog == 'accepted' ? "Aceitar":"Recusar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import ConsentDataPanel from "@/components/ConsentDataPanel.vue";
import ConsentRequestContent from "@/components/ConsentRequestContent.vue";

export default {
  name: "PublicLinkResponse",

  components: {
    ConsentDataPanel,
    ConsentRequestContent,
  },

  data: () => ({
    unexpectedError: false,
    notFoundError: false,
    loadingData: true,
    consent: {},
    loadingSave: false,
    confirmDialog: false,
  }),

  computed: {
    bearer() {
      return (this.consent.bearer ? this.consent.bearer : this.consent.new_bearer) || {};
    },
  },

  methods: {
    getData(){
      this.loadingData = true;
      this.unexpectedError = false;
      this.notFoundError = false;
      this.$axios.get(`/consent/public/${this.$route.params.public_code}`)
      .then((response) => {
        this.consent = response.data;
      })
      .catch((error) => {
        if(error.response){
          if(error.response.status == 404){
            this.notFoundError = true;
            return;
          }
        }
        this.unexpectedError = true;
      })
      .finally(() => {
        this.loadingData = false;
      })
    },
    sendAnswer() {
      const answer = this.confirmDialog;
      this.loadingSave = true;
      this.$axios.put(`/consent/public/${this.$route.params.public_code}/${answer}`)
      .then(() => {
        this.confirmDialog = false;
        this.$showSuccessMessage("Solicitação de Consentimento respondida", "Utilize o aplicativo para ver mais detalhes");
        this.$router.replace({ name: "landing-install-app" });
      })
      .catch((error) => {
        if(error.response){
          if(error.response.status == 404){
            this.notFoundError = true;
            return;
          }
        }
        this.$showMessage('error', "Ocorreu um erro inesperado, tente novamente ou contate o suporte");
      })
      .finally(() => {
        this.loadingSave = false;
      })
    },
  },
  mounted() {
    this.getData()
  },
};
</script>
