<template>  
  <div class="
    panel
    mb-4
    rounded
    fill-height
    d-flex flex-column
  ">
    <div
      class="
        pa-2
        primary lighten-3 white--text
        font-weight-bold
      "
    >
      {{ title }}
    </div>
    <div class="grey lighten-2 flex-grow-1">
      <div v-if="data" class="py-3 px-4">
        <div
          v-for="item in data" :key="item.id"
          class="my-1"
        >
          <div class="font-weight-medium text-">
            {{ item.title }}
          </div>
          <div>
            {{ item.description }}
          </div>
        </div>
      </div>
      <div v-else class="pa-4">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConsentDataPanel",

  props: {
    title: {required: true, type: String},
    data: {type: Array},
  },
}
</script>

<style scoped>
.panel {
  border: #E0E0E0 solid 1px;
  overflow: hidden;
}
</style>